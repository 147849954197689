<script>
export default {
  name: 'Slider',
  props: {
    value: {
      type: Number,
      required: true
    },
    steps: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      isDragging: false,
      sliderTrackRef: null,
      showTooltip: false
    }
  },

  methods: {
    // 计算百分比
    calculatePercentage(clientX) {
      if (!this.$refs.sliderTrackRef) return
      const rect = this.$refs.sliderTrackRef.getBoundingClientRect()
      const offsetX = Math.max(0, Math.min(clientX - rect.left, rect.width))
      const newPercentage = Math.round((offsetX / rect.width) * 100)
      this.$emit('input', Math.max(0, Math.min(100, newPercentage)))
    },

    // 处理节点点击
    handleStepClick(value) {
      this.$emit('input', value)
    },

    // 处理轨道点击
    handleTrackClick(event) {
      this.calculatePercentage(event.clientX)
    },

    // 处理拖动开始
    handleDragStart(event) {
      event.preventDefault()
      this.isDragging = true
      this.showTooltip = true
      
      // 鼠标事件
      document.addEventListener('mousemove', this.handleDragMove)
      document.addEventListener('mouseup', this.handleDragEnd)
      
      // 触摸事件
      document.addEventListener('touchmove', this.handleDragMove, { passive: false })
      document.addEventListener('touchend', this.handleDragEnd)
    },

    // 处理拖动移动
    handleDragMove(event) {
      if (!this.isDragging) return
      event.preventDefault()
      
      const clientX = event instanceof MouseEvent 
        ? event.clientX 
        : event.touches[0].clientX
        
      this.calculatePercentage(clientX)
    },

    // 处理拖动结束
    handleDragEnd() {
      this.isDragging = false
      this.showTooltip = false
      
      // 移除鼠标事件
      document.removeEventListener('mousemove', this.handleDragMove)
      document.removeEventListener('mouseup', this.handleDragEnd)
      
      // 移除触摸事件
      document.removeEventListener('touchmove', this.handleDragMove)
      document.removeEventListener('touchend', this.handleDragEnd)
    }
  }
}
</script>

<template>
  <div class="slider">
    <div 
      ref="sliderTrackRef"
      class="slider-track"
      @click="handleTrackClick"
    >
      <div 
        class="slider-fill" 
        :style="{ width: `${value}%` }"
      ></div>
      <div 
        class="slider-handle"
        :style="{ left: `${value}%` }"
        @mousedown="handleDragStart"
        @touchstart="handleDragStart"
      >
        <div 
          class="slider-tooltip"
          v-show="showTooltip || isDragging"
        >
          {{ value }}%
        </div>
      </div>
    </div>
    <div class="slider-dots">
      <span 
        v-for="step in steps" 
        :key="step"
        :class="{ active: value >= step }"
        @click="handleStepClick(step)"
      ></span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.slider {
  --primary-color: #00b897;
  --danger-color: #f6465d;
  --border-color: #f5f5f5;
  --text-primary: #fff;
  --text-secondary: #fff;

  .slider-track {
    height: 2px;
    background: var(--border-color);
    position: relative;
    margin: 8px 0;
    cursor: pointer;
    touch-action: none;
    user-select: none;

    .slider-fill {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      background: var(--primary-color);
      transition: width 0.1s ease;
      z-index: 1;
    }

    .slider-handle {
      position: absolute;
      top: 50%;
      width: 16px;
      height: 16px;
      background: var(--primary-color);
      border-radius: 50%;
      transform: translate(-50%, -50%);
      cursor: grab;
      transition: transform 0.1s ease;
      z-index: 3;
      touch-action: none;
      user-select: none;

      &:hover {
        transform: translate(-50%, -50%) scale(1.2);
      }

      &:active {
        cursor: grabbing;
        transform: translate(-50%, -50%) scale(1.1);
      }

      .slider-tooltip {
        position: absolute;
        top: -30px;
        left: 50%;
        transform: translateX(-50%);
        background: var(--primary-color);
        color: white;
        padding: 2px 6px;
        border-radius: 4px;
        font-size: 12px;
        white-space: nowrap;
        pointer-events: none;
        
        &::after {
          content: '';
          position: absolute;
          bottom: -4px;
          left: 50%;
          transform: translateX(-50%);
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-top: 4px solid var(--primary-color);
        }
      }

      &:hover .slider-tooltip {
        display: block;
      }
    }
  }

  .slider-dots {
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-top: -12px;

    span {
      width: 6px;
      height: 6px;
      background: var(--border-color);
      border-radius: 50%;
      cursor: pointer;
      transition: all 0.2s ease;
      position: relative;
      z-index: 2;

      &.active {
        width: 8px;
        height: 8px;
        background: var(--primary-color);
        margin-top: -1px;
        transform: scale(1.1);
      }

      &:hover {
        transform: scale(1.2);
      }
    }
  }
}
</style> 