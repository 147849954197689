<template>
  <div class="container">
    <van-pull-refresh
      v-model="refreshLoading"
      @refresh="onRefresh"
      :pulling-text="$t('home.t52')"
      :loosing-text="$t('home.t53')"
      :loading-text="$t('home.t54')"
    >
      <div class="box token_info" v-if="token_info">
        <img
          src="../assets/info2.png"
          v-if="token_info.address != $store.state.address0"
          class="info_icon"
          @click="showTokenInfoPop"
        />
        <div class="token df jcsb">
          <div class="name df jcsb" @click="showTokensPop">
            <img :src="token_info.tokenImg" class="tokenimg" alt="" />
            <div>{{ token_info.name }}</div>
            <img src="../assets/more_token.png" class="more" alt="" />
          </div>
          <!-- <div class="add_token" @click="addToken">添加代币</div> -->

          <!-- 参数检查  我要挂单 -->
          <van-button
            type="info"
            @click="showSellPop"
            :loading="showSellPopLoading"
            :loading-text="$t('home.t1')"
            >{{ $t("home.t2") }}</van-button
          >
        </div>
        <div class="token_data">
          <div class="df jcsb">
            <div>
              <b
                >{{ $utils.numToK(token_info.price, 4, 2) }}
                {{ token_info.pair_name }} / {{ token_info.name }}</b
              >
            </div>
            <div
              :class="
                Number(token_info.price) != Number(token_info.price24h)
                  ? Number(token_info.price) > Number(token_info.price24h)
                    ? 'green'
                    : 'red'
                  : ''
              "
            >
              {{ token_info.increase
              }}<span style="color: #000; font-weight: 400">(24H)</span>
            </div>
          </div>
          <div class="df jcsb">
            <!-- 24小时交易量 -->
            <div>{{ $t("home.t3") }}</div>
            <div>
              {{ $utils.numToK(token_info.day_deal, 4, 2) }}
              {{ token_info.name }}
            </div>
          </div>
          <div class="df jcsb">
            <!-- 24小时最高价 -->
            <div>{{ $t("home.t4") }}</div>
            <div>
              {{
                $utils.numToK(contract.fromWei(token_info.day_max_price), 4, 2)
              }}
              {{ token_info.pair_name }}
            </div>
          </div>
          <div class="df jcsb">
            <!-- 24小时最低价 -->
            <div>{{ $t("home.t5") }}</div>
            <div>
              {{
                $utils.numToK(contract.fromWei(token_info.day_min_price), 4, 2)
              }}
              {{ token_info.pair_name }}
            </div>
          </div>
        </div>
      </div>
      <div class="box chart" v-if="chartData.rows.length > 0">
        <VeLine
          style="z-index: 1"
          :data="chartData"
          height="160px"
          :legend-visible="false"
          :colors="['#2b6cec']"
          :extend="chartExtend"
        />
      </div>
      <div class="content home">
        <van-tabs
          v-model="active"
          @change="changeTab"
          sticky
          animated
          swipeable
          offset-top="50px"
          class="my_tabs"
          line-height="0"
          title-inactive-color="#000"
          title-active-color="#fff"
          background="#fff"
        >
          <!-- t6: 市场 -->
          <van-tab :title="$t('home.t6')">
            <Market
              @openBuyPop="showBuyPop"
              :key="marketKey"
              ref="market"
              :tabIndex="active"
            />
          </van-tab>
          <!-- t7: 交易动态 -->
          <van-tab :title="$t('home.t7')">
            <Trade :key="tradeKey" ref="trade" />
          </van-tab>
          <!-- t8: 我的订单 -->
          <van-tab :title="$t('home.t8')">
            <Order :contract="contract" :key="orderKey" ref="order" />
          </van-tab>
        </van-tabs>
      </div>
    </van-pull-refresh>

    <!-- 行情 -->
    <van-popup
      v-model="show_tokens"
      round
      position="bottom"
      :style="{ maxHeight: '85%', minHeight: '65%' }"
    >
      <div class="pop_box" style="padding: 0 !important">
        <!-- t9: 行情 -->
        <div class="title">{{ $t("home.t9") }}</div>
        <div class="search_box">
          <div class="icon">
            <img src="../assets/search.png" alt="" />
          </div>
          <!-- t10: 搜索 -->
          <van-field
            v-model="search_txt"
            :placeholder="$t('home.t10')"
            class="input"
            style="background-color: #f5f5f5"
            @input="search"
          />
        </div>
        <div class="subtotal">
          <div class="th">
            <div>
              <!-- t11: 币种 -->
              <span>{{ $t("home.t11") }}</span>
            </div>
            <div>
              <!-- t12: 最新价 -->
              <span>{{ $t("home.t12") }}</span>
            </div>
            <div>
              <!-- t13: 涨跌幅 -->
              <span>{{ $t("home.t13") }}</span>
            </div>
          </div>
          <div class="tbody" v-if="tokens.length > 0">
            <div
              class="tr"
              v-for="(item, index) in tokens"
              :key="index"
              @click="selectToken(item)"
            >
              <div class="name">
                <img :src="item.tokenImg" alt="" />
                <span>{{ item.name }}</span>
              </div>
              <div>
                {{ $utils.numToK(item.price, 4, 2) }} {{ item.pair_name }}
              </div>
              <div
                :class="
                  Number(item.price) != Number(item.price24h)
                    ? Number(item.price) > Number(item.price24h)
                      ? 'green'
                      : 'red'
                    : ''
                "
              >
                {{ item.increase }}
              </div>
            </div>
          </div>
          <div v-else class="none">
            <img src="../assets/no_record.png" alt="" />
            <!-- t14: 暂无数据 -->
            <div>{{ $t("home.t14") }}</div>
          </div>
        </div>
      </div>
    </van-popup>

    <!-- 挂单 -->
    <van-popup
      v-model="show_sell"
      round
      position="bottom"
      :style="{ maxHeight: '85%', minHeight: '65%' }"
      @closed="closeSellPop"
      :close-on-click-overlay="!confirmSellLoading"
    >
      <div class="pop_box">
        <!-- t15: 我要挂单 -->
        <div class="title">
          {{ $t("home.t15") }} - {{ token_info && token_info.name }}
        </div>
        <div class="item">
          <!-- t16: 挂单数量 -->
          <div class="input_label">{{ $t("home.t16") }}</div>
          <div class="input_box">
            <van-field
              v-model="amount"
              :disabled="confirmSellLoading"
              type="number"
              maxlength="14"
              placeholder="0.0001"
              class="input"
              style="background-color: #f5f5f5"
            />
            <div class="icon" v-if="token_info">{{ token_info.name }}</div>
          </div>
          <div class="df jcsb" style="margin-top: 8px; padding: 0 2px">
            <!-- t17: 余额： -->
            <span class="balance"
              >{{ $t("home.t17")
              }}{{ $utils.numToK(token_balance, 4, 2) }}</span
            >
            <!-- t55:全部 -->
            <span class="all" @click="all">{{ $t("home.t55") }}</span>
          </div>
        </div>

        <div class="item">
          <!-- t18: 挂单单价 -->
          <div class="input_label">{{ $t("home.t18") }}</div>
          <div class="input_box">
            <van-field
              v-model="price"
              :disabled="confirmSellLoading"
              type="number"
              maxlength="14"
              placeholder="0.0001"
              class="input"
              style="background-color: #f5f5f5"
            />
            <div class="icon">{{ token_info.pair_name }}</div>
          </div>
        </div>

        <div class="item">
          <!-- t19: 预计收益 -->
          <div class="input_label">{{ $t("home.t19") }}</div>
          <div class="input_box disabled">
            <div style="flex: 1">
              {{ $utils.numToK(Number(amount) * price, 8, 2) }}
            </div>
            <div class="icon">{{ token_info.pair_name }}</div>
          </div>
        </div>

        <div class="item">
          <!-- t20: 手续费 -->
          <div class="input_label">
            {{ $t("home.t20") }}(<del>0.5%</del> {{ FeeRate.sellFee * 100 }}%)
          </div>
          <div class="input_box disabled">
            <div style="flex: 1">
              {{ $utils.numToK(Number(amount) * FeeRate.sellFee, 8, 0) }}
            </div>
            <div class="icon" v-if="token_info">{{ token_info.name }}</div>
          </div>
          <div class="bote" v-if="token_info.name == 'BoTe'">
            <div>{{ $t("home.t56") }}</div>
          </div>
        </div>

        <div class="total">
          <!-- t21: 支付总价 -->
          <div>{{ $t("home.t21") }}</div>
          <div class="total_amount" v-if="token_info">
            {{
              $utils.numToK(
                Number(amount) + Number(amount) * FeeRate.sellFee,
                8,
                2
              )
            }}
            {{ token_info.name }}
          </div>
        </div>

        <div class="btn_box">
          <!-- t22: 取消 -->
          <van-button
            plain
            type="info"
            @click="show_sell = false"
            v-if="!confirmSellLoading"
            >{{ $t("home.t22") }}</van-button
          >
          <!-- t23: 确认 -->
          <van-button
            type="info"
            @click="confirmSell"
            :loading="confirmSellLoading"
            :loading-text="confirmSellLoadingTXT"
            >{{ $t("home.t23") }}</van-button
          >
        </div>
      </div>
    </van-popup>

    <!-- 购买 -->
    <van-popup
      v-model="show_buy"
      round
      position="bottom"
      :style="{ maxHeight: '85%', minHeight: '65%' }"
      @closed="closeBuyPop"
      :close-on-click-overlay="!confirmBuyLoading"
    >
      <div class="pop_box" v-if="order_item">
        <!-- t24: 确认购买 -->
        <div class="title">{{ $t("home.t24") }}</div>

        <div class="item">
          <!-- t25: 到账地址 -->
          <div class="input_label">{{ $t("home.t25") }}</div>
          <div class="input_box disabled">
            <div style="flex: 1">{{ account }}</div>
          </div>
        </div>

        <div class="item">
          <!-- t26: 购买数量 -->
          <div class="input_label">{{ $t("home.t26") }}</div>
          <div class="input_box disabled">
            <div style="flex: 1">
              {{ $utils.numToK(order_item.amount1, 8, 2) }}
            </div>
            <div class="icon" v-if="token_info">{{ token_info.name }}</div>
          </div>
        </div>
        <div class="item">
          <!-- t27: 单价 -->
          <div class="input_label">{{ $t("home.t27") }}</div>
          <div class="input_box disabled">
            <div style="flex: 1">
              {{ $utils.numToK(order_item.price1, 8, 2) }}
            </div>
            <div class="icon">{{ token_info.pair_name }}</div>
          </div>
        </div>
        <div class="item">
          <!-- t28: 手续费 -->
          <div class="input_label">
            {{ $t("home.t28") }}(<del>0.2%</del> {{ FeeRate.buyFee * 100 }}%)
          </div>
          <div class="input_box disabled">
            <div style="flex: 1">
              {{
                $utils.numToK(
                  order_item.price1 * order_item.amount1 * FeeRate.buyFee,
                  8,
                  2
                )
              }}
            </div>
            <div class="icon">{{ token_info.pair_name }}</div>
          </div>
          <div class="bote" v-if="token_info.name == 'BoTe'">
            <div>{{ $t("home.t56") }}</div>
          </div>
        </div>

        <div class="total" style="height: 88px">
          <!-- t29: 支付总价 -->
          <div>{{ $t("home.t29") }}</div>
          <div class="total_amount" style="flex-direction: column">
            <span
              >{{
                $utils.numToK(
                  order_item.price1 * order_item.amount1 * (1 + FeeRate.buyFee),
                  8,
                  2
                )
              }}
              {{ token_info.pair_name }}</span
            >
            <span class="fee"
              >{{ $utils.numToK(order_item.price1 * order_item.amount1, 8, 0)
              }}{{ token_info.pair_name }} +
              {{
                $utils.numToK(
                  order_item.price1 * order_item.amount1 * FeeRate.buyFee,
                  4,
                  0
                )
              }}{{ token_info.pair_name }}</span
            >
          </div>
        </div>

        <div class="btn_box">
          <!-- t30: 取消 -->
          <van-button
            plain
            type="info"
            @click="show_buy = false"
            v-if="!confirmBuyLoading"
            >{{ $t("home.t30") }}</van-button
          >
          <!-- t31: 确认 -->
          <van-button
            type="info"
            @click="confirmBuy"
            :loading="confirmBuyLoading"
            :loading-text="confirmBuyLoadingTXT"
            >{{ $t("home.t31") }}</van-button
          >
        </div>
      </div>
    </van-popup>

    <!-- 代币信息 -->
    <van-popup
      v-model="show_token_info"
      round
      position="bottom"
      :style="{ maxHeight: '85%', minHeight: '300px' }"
    >
      <div class="pop_box" v-if="token_info">
        <!-- t46: 代币信息 -->
        <div class="title">
          <span>{{ $t("home.t46") }}</span>
        </div>

        <div class="item">
          <!-- t47: 代币名称 -->
          <div class="input_label">{{ $t("home.t47") }}</div>
          <div class="input_box disabled">
            <div style="flex: 1">{{ token_info.name }}</div>
            <img :src="token_info.tokenImg" style="width: 30px" />
          </div>
        </div>
        <div class="item">
          <!-- t48: 代币合约 -->
          <div class="input_label">{{ $t("home.t48") }}</div>
          <div class="input_box disabled">
            <div style="flex: 1">{{ token_info.address }}</div>
          </div>
        </div>
        <div class="item">
          <!-- t49: 发行量 -->
          <div class="input_label">{{ $t("home.t49") }}</div>
          <div class="input_box disabled">
            <div style="flex: 1">
              {{ $utils.numToK(token_info.totalSupply, 4, 0) }}
            </div>
          </div>
        </div>

        <div class="btn_box">
          <!-- t30: 取消 -->
          <van-button plain type="info" @click="show_token_info = false">{{
            $t("home.t50")
          }}</van-button>
          <!-- t31: 确认 -->
          <van-button
            v-if="token_info.address != $store.state.address0"
            type="info"
            @click="addToken"
            :loading="addTokenLoading"
            :loading-text="$t('home.t51')"
            >{{ $t("home.t51") }}</van-button
          >
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Notify } from "vant-green";
import charts_config from "../utils/charts_config.json";
import VeLine from "v-charts/lib/line.common";
import Market from "../components/MarketView.vue";
import Trade from "../components/TradeView.vue";
import Order from "../components/OrderView.vue";
export default {
  components: {
    VeLine,
    Market,
    Trade,
    Order,
  },
  data() {
    return {
      contract: null, //操作合约
      account: "",
      config: null,
      token_info: null,
      tokens: [],
      tokens_bak: [],

      chartData: charts_config.chartData,
      chartExtend: charts_config.chartExtend,

      timer: null,
      active: 0,

      show_tokens: false,
      search_txt: "",

      marketKey: 0, //市场
      tradeKey: 0, //交易
      orderKey: 0, //订单
      tabRef: ["market", "trade", "order"],
      //手续费
      FeeRate: {
        buyFee: 0,
        sellFee: 0,
      },
      token_balance: 0, //用户token余额

      //挂单
      show_sell: false,
      amount: "",
      price: "",
      showSellPopLoading: false, //打开挂单弹窗loading
      confirmSellLoading: false, //确认挂单loading
      confirmSellLoadingTXT: "",

      //买入
      show_buy: false,
      buy_amount: 0,
      pay_amount: 0,
      order_item: null, //选择的订单
      confirmBuyLoading: false, //确认购买loading
      confirmBuyLoadingTXT: "", //确认购买loading文本

      refreshLoading: false,

      show_token_info: false,
      addTokenLoading: false,
    };
  },
  watch: {
    amount(val) {
      //最多4位小数
      if (val.indexOf(".") != -1) {
        val = val.slice(0, val.indexOf(".") + 5);
        this.amount = val;
      }
    },
    price(val) {
      //最多4位小数
      if (val.indexOf(".") != -1) {
        val = val.slice(0, val.indexOf(".") + 5);
        this.price = val;
      }
    },
  },
  async mounted() {
    this.account = localStorage.getItem("userAccount");
    this.config = this.$store.state.config;
    this.FeeRate = this.$store.state.FeeRate;
    this.token_info = this.$store.state.token_info;
    this.tokens = this.config.Tokens;
    this.tokens_bak = this.tokens;
    this.chartExtend.tooltip = {
      trigger: "axis",
      formatter: (params) => {
        return (
          params[0].axisValueLabel +
          "<br/>" +
          params[0].marker +
          " Price: " +
          Number(Number(params[0].value[1]).toFixed(4)) +
          " " +
          this.token_info.pair_name
        );
      },
    };
    this.chartData.rows = this.$store.state.token_info.price_data;
    this.contract = new this.$BlockchainContract(this.config);

    // setTimeout(async () => {
    // 	//监听滚动
    // 	window.removeEventListener("scroll", this.$refs["market"].handleScroll);
    // 	window.addEventListener("scroll", this.$refs["market"].handleScroll);
    // });
  },
  beforeDestroy() {
    this.timer && clearInterval(this.timer);
  },

  methods: {
    async onRefresh() {
      await this.selectToken(this.$store.state.this_token, false);
      this.refreshLoading = false;
    },
    changeTab(index) {
      this.$refs[this.tabRef[index]].onRefresh(false);
      this.$store.state.tabRefIndex = index;
    },
    async showTokensPop() {
      //查询列表
      this.$toast.loading({
        message: this.$t("home.t32"), //加载中...
        forbidClick: true,
        duration: 0,
      });
      try {
        const result = await this.$api.request("/get_tokens_list", {}, "POST");
        // const result = await this.$api.request("/get_tokens_list_vtest", {}, "POST");
        if (result.code == 200) {
          this.tokens = result.data;
          this.tokens_bak = result.data;
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.$toast.clear();
      }
      this.show_tokens = true;
    },
    handle_increase(price, hours24_price) {
      if (hours24_price <= 0) {
        if (price > 0) {
          return "+100%";
        } else {
          return "+0%";
        }
      }
      console.log(price, hours24_price);
      const fh = price > hours24_price ? "+" : "";
      const incerease = ((price - hours24_price) / hours24_price) * 100;
      return fh + incerease.toFixed(2) + "%";
    },
    search(e) {
      //抖动
      this.timer && clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.tokens = this.tokens_bak.filter((item) => {
          return item.name.toLowerCase().indexOf(e.toLowerCase()) != -1;
        });
      }, 300);
    },
    async selectToken(item, isLoading = true) {
      this.$store.state.this_token = item;
      localStorage.setItem("last_token", JSON.stringify(item));
      this.$refs[this.tabRef[this.active]].onRefresh(isLoading);
      if (isLoading) {
        this.$toast.loading({
          duration: 0,
        });
      }
      this.show_tokens = false;
      try {
        const result = await this.$api.request("/get_token_info", item, "POST");
        if (result.code == 200) {
          this.$store.state.token_info = result.data;
          this.token_info = result.data;
          this.chartData = {
            columns: ["ymd", "price"],
            rows: result.data.price_data,
          };
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.$toast.clear();
      }
    },
    showSellPop() {
      this.showSellPopLoading = true;
      Promise.all([
        this.contract.getFee(),
        this.contract.getBalance(this.account, this.token_info.address),
      ])
        .then(([FeeRate, balance]) => {
          this.FeeRate = FeeRate;
          this.token_balance = balance;
          this.show_sell = true;
        })
        .catch((error) => {
          Notify({
            type: "danger",
            message: this.$t("home.t33"), //网络异常
          });
          console.log(error);
        })
        .finally(() => {
          this.showSellPopLoading = false;
        });
    },
    all() {
      if (this.token_info.address == this.$store.state.address0) {
        this.amount = this.token_balance / (1 + this.FeeRate.sellFee) - 0.0003;
      } else {
        this.amount = this.token_balance / (1 + this.FeeRate.sellFee);
      }
      if (this.amount < 0) {
        this.amount = 0;
      }
    },
    closeSellPop() {
      this.show_sell = false;
      this.amount = "";
      this.price = "";
    },
    confirmSell() {
      if (!this.amount || !this.price) {
        Notify({
          type: "danger",
          message: this.$t("home.t34"), //t34: 请输入挂单数量和单价
        });
        return;
      }
      if (this.amount <= 0 || this.price <= 0) {
        Notify({
          type: "danger",
          message: this.$t("home.t34"), //t35: 请输入正确的挂单数量
        });
        return;
      }

      this.confirmSellLoadingTXT = this.$t("home.t36"); //t36: 参数检查...
      this.confirmSellLoading = true;
      this.contract
        .sell(
          this.token_info.address,
          this.token_info.pair_address,
          this.amount,
          this.price,
          this
        )
        .then((result) => {
          console.log(result);
          if (result && result.hash) {
            Notify({
              type: "success",
              message: this.$t("home.t37"), //t37: 挂单成功
            });
            this.show_sell = false;
            this.amount = "";
            this.price = "";
            this.marketKey++;
          } else {
            Notify({
              type: "danger",
              message: this.$t("home.t38"), //t38: 挂单失败
            });
          }
        })
        .catch((error) => {
          console.log(error);
          Notify({
            type: "danger",
            message: this.$t("home.t39"), //t39: 挂单失败
          });
        })
        .finally(() => {
          this.confirmSellLoading = false;
        });
    },
    showBuyPop(item) {
      this.order_item = item;
      this.show_buy = true;

      // this.$toast.loading({
      // 	duration: 0,
      // })
      // Promise.all([this.contract.getFee()]).then(([FeeRate])=>{
      // 	this.FeeRate = FeeRate;
      // 	this.order_item = item;
      // 	this.show_buy = true;
      // }).catch((error)=>{
      // 	Notify({
      // 		type: "danger",
      // 		message: this.$t("home.t33"), //网络异常
      // 	})
      // 	console.log(error)
      // }).finally(()=>{
      // 	this.$toast.clear();
      // })
    },
    closeBuyPop() {
      this.show_buy = false;
      this.buy_fee = "";
      this.pay_amount = "";
    },
    confirmBuy() {
      this.confirmBuyLoadingTXT = this.$t("home.t40"); //t40: 余额检查...
      this.confirmBuyLoading = true;
      this.contract
        .buy(this.order_item, this)
        .then((result) => {
          console.log(result);
          if (result && result.hash) {
            Notify({
              type: "success",
              message: this.$t("home.t41"), //t41: 购买成功
            });
            this.show_buy = false;
            this.amount = "";
            this.price = "";
            this.marketKey++;
          } else {
            Notify({
              type: "danger",
              message: this.$t("home.t42"), //t42: 购买失败
            });
          }
        })
        .catch((error) => {
          console.log(error);
          Notify({
            type: "danger",
            message: this.$t("home.t43"), //t43: 购买失败
          });
        })
        .finally(() => {
          this.confirmBuyLoading = false;
        });
    },
    showTokenInfoPop() {
      this.show_token_info = true;
    },
    addToken() {
      this.addTokenLoading = true;
      this.contract
        .addToken(this.token_info, this)
        .then((result) => {
          console.log(result);
          if (result === true) {
            Notify({
              type: "success",
              message: this.$t("home.t44"), //t44: 添加成功
            });
            this.show_token_info = false;
          } else {
            Notify({
              type: "danger",
              message: this.$t("home.t45"), //t45: 添加失败
            });
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.addTokenLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  padding: 0 15px calc(env(safe-area-inset-bottom) + 10px) 15px;
  background-size: 100%;
  background-position-y: 50px;
  min-height: calc(100vh - 150px);
  background: url("../assets/bj.png") no-repeat;
  background-size: 100%;
  background-position: 0 50px;
}
.box {
  background: #fff;
  border-radius: 10px;
  padding: 0 16px;
  margin-top: 8px;
}
.token_info {
  position: relative;
  .info_icon {
    position: absolute;
    width: 20px;
    right: 2px;
    top: 2px;
  }
  .token {
    border-bottom: 1px solid #e5e5e5;
  }
  .name {
    height: 74px;
    gap: 6px;
    // font-family: PingFang-SC-Medium;
    font-size: 16px;
    font-weight: normal;
    color: #030c03;
    .tokenimg {
      width: 40px;
      height: 40px;
    }
    .more {
      width: 10px;
    }
    .i_icon {
      width: 20px;
    }
  }
  .token_data {
    padding: 15px 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
    > div {
      font-size: 12px;
      line-height: 1;
      div:nth-child(1) {
        color: #030c03;
      }
      div:nth-child(2) {
        color: #000;
        font-weight: bold;
      }
    }
  }
  .add_token {
    text-align: center;
    color: #1a89fa;
    // padding: 4px 0 10px;
    font-size: 10px;
  }
}
.chart {
  // padding: 20px 0;
  padding: 0 0 20px 0;
}
.content {
  margin-top: 10px;
  padding-bottom: 10px;
  min-height: calc(100vh - 69px - env(safe-area-inset-bottom));
}

.pop_box {
  min-height: 400px;
  padding: 0 20px;

  .title {
    text-align: center;
    line-height: 70px;
    font-size: 20px;
    font-weight: normal;
    color: #000000;
    font-weight: 500;
  }

  .search_box {
    padding-right: 15px;
    display: flex;
    align-items: center;
    height: 44px;
    background-color: #f5f7fa;
    border-radius: 10px;
    border: solid 1px #d9d9d9;
    color: #000;
    margin: 0 15px;
    .icon {
      width: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      > img {
        width: 20px;
        height: 20px;
      }
    }
    .input {
      flex: 1;
      background: red;
      color: #000;
    }
  }

  .subtotal {
    margin-top: 16px;
    border-radius: 6px;
    overflow: hidden;
    height: 60vh;
    .tbody {
      height: calc(100% - 160px);
      padding-bottom: 100px;
      overflow: hidden;
      overflow-y: auto;
      .name {
        display: flex;
        align-items: center;
        gap: 6px;
        img {
          width: 27px;
          height: 27px;
          border-radius: 50%;
        }
      }
    }
    .tr,
    .th {
      display: flex;
      gap: 0 4px;
      justify-content: space-between;
      // font-family: PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #030c03;
      min-height: 47px;
      padding: 2px 20px;
      align-items: center;
      text-align: center;
      > div {
        flex: 1;
        text-align: left;
      }

      > div:nth-child(1) {
        flex: 1.3;
      }

      > div:nth-child(3) {
        flex: 0.6;
      }
    }
    .th {
      > div > span {
        color: #808080;
      }
    }
    .tr {
      // font-family: PingFang-SC-Medium;
      font-weight: normal;
      line-height: 18px;
      letter-spacing: 0px;
    }

    .tr:nth-child(odd) {
      background: #f5f7fa;
    }

    .none {
      display: flex;
      flex-direction: column;
      height: fit-content;
      justify-content: center;
      align-items: center;
      margin-top: 40px;
      user-select: none;
      color: #999;
      padding-bottom: 40px;
      img {
        width: 120px;
        margin-bottom: 10px;
      }
    }
  }

  .item {
    margin-bottom: 24px;

    .input_label {
      margin-left: 2px;
      font-size: 14px;
      color: #000000;
      line-height: 1;
    }
    .input_box {
      margin-top: 10px;
      padding: 0 12px;
      display: flex;
      align-items: center;
      height: 44px;
      background-color: #f5f7fa;
      border-radius: 10px;
      border: solid 1px #d9d9d9;
      color: #000;
      &.disabled {
        background-color: #eee;
      }
      .icon {
        padding-left: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        > img {
          width: 20px;
          height: 20px;
        }
      }
      .input {
        flex: 1;
        background: red;
        color: #000;
      }
    }
    .balance {
      // font-family: PingFang-SC-Medium;
      font-size: 12px;
      line-height: 1;
      color: #808080;
    }
    .all {
      font-size: 12px;
      font-weight: normal;
      line-height: 1;
      color: #2553ea;
    }
  }

  .total {
    padding: 10px;
    height: 70px;
    background: url("../assets/bj.png") no-repeat;
    background-image: linear-gradient(0deg, #f2f6ff 0%, #ccddff 100%);
    border-radius: 8px;

    display: flex;
    flex-direction: column;
    font-size: 14px;
    color: #000;

    .total_amount {
      flex: 1;
      font-size: 22px;
      font-weight: bold;
      color: #000000;
      display: flex;
      justify-content: center;
      align-items: center;
      .fee {
        font-size: 12px;
        color: #404040;
      }
    }
  }

  // position: relative;
  .btn_box {
    // position: absolute;
    left: 20px;
    right: 20px;
    bottom: calc(env(safe-area-inset-bottom) + 38px);
    display: flex;
    gap: 20px;
    margin: 40px 0;
    > button {
      flex: 1;
      height: 38px !important;
      border-radius: 20px !important;
    }
  }
  .bote {
    margin-top: 4px;
    font-size: 12px;
    color: #ff5722;
  }
}
</style>
