import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
    "zh": {
        head: {
            t1: "链接钱包",
            t2: "钱包地址复制成功",
            t3: "断开链接",
            t4: "是否断开钱包链接？",
            t5: "取消",
            t6: "确认",
            t7: '挂单交易',
            t8: '委托交易',
        },
        methods: {
            t0: "未检测到钱包，请刷新页面后再试！",
            t1: "余额检查中...",
            t2: "代币余额不足",
            t3: '余额充足，检查授权',
            t4: '代币授权中...',
            t5: '授权成功',
            t6: '授权失败',
            t7: "执行合约中...",
            t8: '订单不存在',
            t9: '不能购买自己的商品',
            t10: '余额检查中...',
            t11: '代币余额不足',
            t12: '验证中',
            t13: '订单已取消',
            t14: '只能撤销自己的订单',
            t15: '执行合约',
            t16: '用户拒绝了交易',
            t17: '调用合约时发生错误',
            t18: '账户余额不足，无法完成交易',
            t19: '授权额度不足，请增加授权额度',
            t20: '提供的交易金额不足，无法完成交易',
            t21: '合约执行失败，参数错误',
            t22: '无法估算 Gas 限制，请手动设置 gas limit',
            t23: '网络连接异常，请检查您的网络连接',
            t24: '请求超时，请稍后重试',
            t25: '交易已过期，请重试',
            t26: '交易的 gas 价格过低，请提高 gas 价格',
            t27: '交易已被替换',
            t28: '交易耗尽 Gas，请增加 Gas 限制',
            t29: '签名无效，请检查签名参数',
            t30: '交易失败'
        },
        home: {
            t1: "参数检查",
            t2: "我要挂单",
            t3: "24小时交易量",
            t4: "24小时最高价",
            t5: "24小时最低价",
            t6: "市场",
            t7: "交易动态",
            t8: "我的订单",
            t9: "行情",
            t10: "搜索",
            t11: "币种",
            t12: "最新价",
            t13: "涨跌幅",
            t14: "暂无数据",
            t15: "我要挂单",
            t16: "挂单数量",
            t17: "余额：",
            t18: "挂单单价",
            t19: "预计收益",
            t20: "手续费",
            t21: "支付总价",
            t22: "取消",
            t23: "确认",
            t24: "确认购买",
            t25: "到账地址",
            t26: "购买数量",
            t27: "单价",
            t28: "手续费",
            t29: "支付总价",
            t30: "取消",
            t31: "确认",
            t32: "加载中...",
            t33: "网络异常",
            t34: "请输入挂单数量和单价",
            t35: "代币余额不足",
            t36: "参数检查...",
            t37: "挂单成功",
            t38: "挂单失败",
            t39: "挂单失败",
            t40: "余额检查...",
            t41: "购买成功",
            t42: "购买失败",
            t43: "购买失败",
            t44: "添加成功",
            t45: "添加失败",
            t46: "代币信息",
            t47: "代币名称",
            t48: "代币合约",
            t49: "发行量",
            t50: "关闭",
            t51: "添加到钱包",
            t52: "下拉即可刷新...",
            t53: "释放即可刷新...",
            t54: "加载中...",
            t55: "全部",
            t56: "注：BoTe代币每次转账总金额的1%将打入黑洞进行销毁，具体销毁数量以合约实际销毁数量为准。"
        },
        delegation: {
            t1: '当前委托',
            t2: '历史委托',
            t3: '交易历史',
            t4: '图标',
            t5: '盘口',
            t6: '交易动态',
            t7: '图标',
            t8: '买入',
            t9: '卖出',
            t10: '价格',
            t11: '买入数量',
            t12: '（最低购买数量：10）',
            t13: '手续费:',
            t14: '支付总价',
            t15: '余额：',
            t16: '卖出数量',
            t17: '价格(K)',
            t18: '成交量',
            t19: '时间',
            t20: '当前代币不支持交易',
            t21: '推荐',
            t22: '自选',
            t23: '剩余数量',
            t24: '数量',
            t25: '更多',
            t26: '输入的数值不能少于 0.0001，请重新输入',
            t27: '查看更多',
            t28: '滑点（{n}%）',
            t29: '输入的单价或买入数量不能少于 0.0001，请重新输入',
            t30: '输入的单价或卖出数量不能少于 0.0001，请重新输入',
            t31: '预估',
        },
        market: {
            t1: "价格优先",
            t2: "数量优先",
            t3: "编号",
            t4: "单价",
            t5: "购买",
            t6: "暂无数据",
        },
        trade: {
            t1: "成交",
            t2: "总价",
            t3: "编号",
            t4: "时间",
            t5: "交易Hash",
            t6: "暂无数据",
        },
        order: {
            t1: "当前委托",
            t2: "历史委托",
            t3: "购买订单",
            t4: "编号",
            t5: "挂单时间",
            t6: "交易时间",
            t7: "交易Hash",
            t8: "已完成",
            t9: "已取消",
            t10: "撤回订单",
            t11: "暂无数据",
            t12: "挂单Hash",
            t13: "成交Hash",
            t14: "撤单Hash",
            t15: "单价",
            t16: "部分成交",
            t17: "未成交",
            t18: "状态",
            t19: "卖出",
            t20: "买入",
            t21: "未成交金额:",
            t22: "撤回委托",
            t23: "已取消",
            t24: "撤回成功",
            t25: "撤回失败",
            t26: "未成交",
            t27: "委托中",
            t28: "卖盘市场",
            t29: "买盘市场",
        }
    },
    "zh-hant": {
        head: {
            t1: "鏈接錢包",
            t2: "錢包地址復製成功",
            t3: "斷開鏈接",
            t4: "是否斷開錢包鏈接？",
            t5: "取消",
            t6: "確認",
            t7: '掛單交易',
            t8: '委托交易',
        },
        methods: {
            t0: "未檢測到錢包，請刷新頁面後再試！",
            t1: "余額檢查中...",
            t2: "代幣余額不足",
            t3: '余額充足，檢查授權',
            t4: '代幣授權中...',
            t5: '授權成功',
            t6: '授權失敗',
            t7: "執行合約中...",
            t8: '訂單不存在',
            t9: '不能購買自己的商品',
            t10: '余額檢查中...',
            t11: '代幣余額不足',
            t12: '驗證中',
            t13: '訂單已取消',
            t14: '只能撤銷自己的訂單',
            t15: '執行合約',
            t16: '用戶拒絕了交易',
            t17: '調用合約時發生錯誤',
            t18: '賬戶余額不足，無法完成交易',
            t19: '授權額度不足，請增加授權額度',
            t20: '提供的交易金額不足，無法完成交易',
            t21: '合約執行失敗，參數錯誤',
            t22: '無法估算 Gas 限製，請手動設置 gas limit',
            t23: '網絡連接異常，請檢查您的網絡連接',
            t24: '請求超時，請稍後重試',
            t25: '交易已過期，請重試',
            t26: '交易的 gas 價格過低，請提高 gas 價格',
            t27: '交易已被替換',
            t28: '交易耗盡 Gas，請增加 Gas 限製',
            t29: '簽名無效，請檢查簽名參數',
            t30: '交易失敗'
        },
        home: {
            t1: "參數檢查",
            t2: "我要掛單",
            t3: "24小時交易量",
            t4: "24小時最高價",
            t5: "24小時最低價",
            t6: "市場",
            t7: "交易動態",
            t8: "我的訂單",
            t9: "行情",
            t10: "搜索",
            t11: "幣種",
            t12: "最新價",
            t13: "漲跌幅",
            t14: "暫無數據",
            t15: "我要掛單",
            t16: "掛單數量",
            t17: "余額：",
            t18: "掛單單價",
            t19: "預計收益",
            t20: "手續費",
            t21: "支付總價",
            t22: "取消",
            t23: "確認",
            t24: "確認購買",
            t25: "到賬地址",
            t26: "購買數量",
            t27: "單價",
            t28: "手續費",
            t29: "支付總價",
            t30: "取消",
            t31: "確認",
            t32: "加載中...",
            t33: "網絡異常",
            t34: "請輸入掛單數量和單價",
            t35: "代幣余額不足",
            t36: "參數檢查...",
            t37: "掛單成功",
            t38: "掛單失敗",
            t39: "掛單失敗",
            t40: "余額檢查...",
            t41: "購買成功",
            t42: "購買失敗",
            t43: "購買失敗",
            t44: "添加成功",
            t45: "添加失敗",
            t46: "代幣信息",
            t47: "代幣名稱",
            t48: "代幣合約",
            t49: "發行量",
            t50: "關閉",
            t51: "添加到錢包",
            t52: "下拉即可刷新...",
            t53: "釋放即可刷新...",
            t54: "加載中...",
            t55: "全部",
            t56: "註：BoTe代幣每次轉賬總金額的1%將打入黑洞進行銷毀，具體銷毀數量以合約實際銷毀數量為準。"
        },
        delegation: {
            t1: '當前委托',
            t2: '歷史委托',
            t3: '交易歷史',
            t4: '圖標',
            t5: '盤口',
            t6: '交易動態',
            t7: '圖標',
            t8: '買入',
            t9: '賣出',
            t10: '價格',
            t11: '買入數量',
            t12: '（最低購買數量：10）',
            t13: '手續費:',
            t14: '支付總價',
            t15: '余額：',
            t16: '賣出數量',
            t17: '價格(K)',
            t18: '成交量',
            t19: '時間',
            t20: '當前代幣不支持交易',
            t21: '推薦',
            t22: '自選',
            t23: '剩餘數量',
            t24: '數量',
            t25: '更多',
            t26: '輸入的數值不能少於 0.0001，請重新輸入',
            t27: '查看更多',
            t28: '滑點（{n}%）',
            t29: '輸入的單價或買入數量不能少於 0.0001，請重新輸入',
            t30: '輸入的單價或賣出數量不能少於 0.0001，請重新輸入',
            t31: '預估',
        },
        market: {
            t1: "價格優先",
            t2: "數量優先",
            t3: "編號",
            t4: "單價",
            t5: "購買",
            t6: "暫無數據",
        },
        trade: {
            t1: "成交",
            t2: "總價",
            t3: "編號",
            t4: "時間",
            t5: "交易Hash",
            t6: "暫無數據",
        },
        order: {
            t1: "當前委托",
            t2: "歷史委托",
            t3: "購買訂單",
            t4: "編號",
            t5: "掛單時間",
            t6: "交易時間",
            t7: "交易Hash",
            t8: "已完成",
            t9: "已取消",
            t10: "撤回訂單",
            t11: "暫無數據",
            t12: "掛單Hash",
            t13: "成交Hash",
            t14: "撤單Hash",
            t15: "單價",
            t16: "部分成交",
            t17: "未成交",
            t18: "狀態",
            t19: "賣出",
            t20: "買入",
            t21: "未成交金額:",
            t22: "撤回委托",
            t23: "已取消",
            t24: "撤回成功",
            t25: "撤回失敗",
            t26: "未成交",
            t27: "委托中",
            t28: "卖盘市场",
            t29: "买盘市场",
        }
    },
    "en": {
        "head": {
            "t1": "Connect Wallet",
            "t2": "Wallet Address Copied Successfully",
            "t3": "Disconnect Wallet",
            "t4": "Are you sure you want to disconnect the wallet?",
            "t5": "Cancel",
            "t6": "Confirm",
            "t7": "Place Order",
            "t8": "Delegated Order"
        },
        "methods": {
            "t0": "No wallet detected, please refresh the page and try again!",
            "t1": "Checking balance...",
            "t2": "Insufficient token balance",
            "t3": "Balance sufficient, checking authorization",
            "t4": "Authorizing token...",
            "t5": "Authorization successful",
            "t6": "Authorization failed",
            "t7": "Executing contract...",
            "t8": "Order does not exist",
            "t9": "Cannot buy your own product",
            "t10": "Checking balance...",
            "t11": "Insufficient token balance",
            "t12": "Verifying...",
            "t13": "Order has been canceled",
            "t14": "Can only cancel your own order",
            "t15": "Execute contract",
            "t16": "User rejected the transaction",
            "t17": "Error occurred while calling the contract",
            "t18": "Account balance insufficient to complete transaction",
            "t19": "Authorization limit insufficient, please increase authorization limit",
            "t20": "Insufficient transaction amount provided, cannot complete transaction",
            "t21": "Contract execution failed, parameter error",
            "t22": "Cannot estimate Gas limit, please manually set gas limit",
            "t23": "Network connection abnormal, please check your network",
            "t24": "Request timed out, please try again later",
            "t25": "Transaction expired, please retry",
            "t26": "Gas price too low for transaction, please increase gas price",
            "t27": "Transaction has been replaced",
            "t28": "Transaction ran out of Gas, please increase Gas limit",
            "t29": "Invalid signature, please check signature parameters",
            "t30": "Transaction failed"
        },
        "home": {
            "t1": "Parameter Check",
            "t2": "Place Order",
            "t3": "24h Trading Volume",
            "t4": "24h Highest Price",
            "t5": "24h Lowest Price",
            "t6": "Market",
            "t7": "Trading Records",
            "t8": "My Orders",
            "t9": "Market Price",
            "t10": "Search",
            "t11": "Currency",
            "t12": "Latest Price",
            "t13": "Change Percentage",
            "t14": "No Data Available",
            "t15": "Place Order",
            "t16": "Order Quantity",
            "t17": "Balance:",
            "t18": "Order Unit Price",
            "t19": "Estimated Profit",
            "t20": "Transaction Fee",
            "t21": "Total Payment",
            "t22": "Cancel",
            "t23": "Confirm",
            "t24": "Confirm Purchase",
            "t25": "Deposit Address",
            "t26": "Purchase Quantity",
            "t27": "Unit Price",
            "t28": "Transaction Fee",
            "t29": "Total Payment",
            "t30": "Cancel",
            "t31": "Confirm",
            "t32": "Loading...",
            "t33": "Network Abnormality",
            "t34": "Please enter order quantity and unit price",
            "t35": "Insufficient token balance",
            "t36": "Parameter Checking...",
            "t37": "Order Successful",
            "t38": "Order Failed",
            "t39": "Order Failed",
            "t40": "Balance Checking...",
            "t41": "Purchase Successful",
            "t42": "Purchase Failed",
            "t43": "Purchase Failed",
            "t44": "Added Successfully",
            "t45": "Add Failed",
            "t46": "Token Information",
            "t47": "Token Name",
            "t48": "Token Contract",
            "t49": "Total Supply",
            "t50": "Close",
            "t51": "Add to Wallet",
            "t52": "Pull to Refresh...",
            "t53": "Release to Refresh...",
            "t54": "Loading...",
            "t55": "All",
            "t56": "Note: For every transfer of BoTe tokens, 1% of the total amount will be burned into the black hole. The specific amount burned will depend on the actual burn quantity in the contract."
        },
        "delegation": {
            "t1": "Current Delegations",
            "t2": "Historical Delegations",
            "t3": "Transaction History",
            "t4": "Icon",
            "t5": "Market Depth",
            "t6": "Trading Records",
            "t7": "Icon",
            "t8": "Buy",
            "t9": "Sell",
            "t10": "Price",
            "t11": "Buy Quantity",
            "t12": "(Minimum Purchase Quantity: 10)",
            "t13": "Transaction Fee:",
            "t14": "Total Payment",
            "t15": "Balance:",
            "t16": "Sell Quantity",
            "t17": "Price (K)",
            "t18": "Volume",
            "t19": "Time",
            "t20": "Current token does not support trading",
            "t21": "Recommended",
            "t22": "Self-Selected",
            "t23": "Remaining Quantity",
            "t24": "Quantity",
            "t25": 'More',
            "t26": 'The input value cannot be less than 0.0001, please re-enter',
            "t27": 'View More',
            "t28": 'Slippage（{n}%）',
            "t29": 'The input value cannot be less than 0.0001, please re-enter',
            "t30": 'The input value cannot be less than 0.0001, please re-enter',
            "t31": 'Estimate'
        },
        "market": {
            "t1": "Price Priority",
            "t2": "Quantity Priority",
            "t3": "ID",
            "t4": "Unit Price",
            "t5": "Buy",
            "t6": "No Data Available"
        },
        "trade": {
            "t1": "Transaction",
            "t2": "Total Price",
            "t3": "ID",
            "t4": "Time",
            "t5": "Transaction Hash",
            "t6": "No Data Available"
        },
        "order": {
            "t1": "Current Orders",
            "t2": "Historical Orders",
            "t3": "Purchase Orders",
            "t4": "ID",
            "t5": "Order Time",
            "t6": "Transaction Time",
            "t7": "Transaction Hash",
            "t8": "Completed",
            "t9": "Canceled",
            "t10": "Withdraw Order",
            "t11": "No Data Available",
            "t12": "Order Hash",
            "t13": "Transaction Hash",
            "t14": "Cancel Hash",
            "t15": "Unit Price",
            "t16": "Partial Transaction",
            "t17": "Not Completed",
            "t18": "Status",
            "t19": "Sell",
            "t20": "Buy",
            "t21": "Uncompleted Amount:",
            "t22": "Withdraw Delegation",
            "t23": "Canceled",
            "t24": "Withdraw Successful",
            "t25": "Withdraw Failed",
            "t26": "Not Completed",
            "t27": "Delegating",
            "t28": "Sell Market",
            "t29": "Buy Market"
        }
    },
    "ja": {
        "head": {
            "t1": "ウォレットを接続",
            "t2": "ウォレットアドレスが正常にコピーされました",
            "t3": "ウォレットの接続を切断",
            "t4": "ウォレットの接続を切断しますか？",
            "t5": "キャンセル",
            "t6": "確認",
            "t7": "注文を出す",
            "t8": "委託注文"
        },
        "methods": {
            "t0": "ウォレットが検出されませんでした。ページをリフレッシュして再試行してください！",
            "t1": "残高を確認中...",
            "t2": "トークン残高が不足しています",
            "t3": "残高は十分です。権限を確認中",
            "t4": "トークンの権限付与中...",
            "t5": "権限付与成功",
            "t6": "権限付与失敗",
            "t7": "契約を実行中...",
            "t8": "注文が存在しません",
            "t9": "自分の商品は購入できません",
            "t10": "残高を確認中...",
            "t11": "トークン残高が不足しています",
            "t12": "確認中...",
            "t13": "注文はキャンセルされました",
            "t14": "自分の注文のみキャンセルできます",
            "t15": "契約を実行",
            "t16": "ユーザーが取引を拒否しました",
            "t17": "契約呼び出し中にエラーが発生しました",
            "t18": "アカウントの残高が不足しており、取引を完了できません",
            "t19": "権限の上限が不足しています。権限の上限を増加してください",
            "t20": "提供された取引金額が不足しており、取引を完了できません",
            "t21": "契約実行失敗、パラメータエラー",
            "t22": "Gas制限の予測ができません。ガスリミットを手動で設定してください",
            "t23": "ネットワーク接続に異常があります。ネットワーク接続を確認してください",
            "t24": "リクエストがタイムアウトしました。後で再試行してください",
            "t25": "取引が期限切れです。再試行してください",
            "t26": "取引のガス価格が低すぎます。ガス価格を上げてください",
            "t27": "取引が置き換えられました",
            "t28": "取引がガスを使い果たしました。ガスリミットを増加してください",
            "t29": "署名が無効です。署名パラメータを確認してください",
            "t30": "取引に失敗しました"
        },
        "home": {
            "t1": "パラメータ確認",
            "t2": "注文を出す",
            "t3": "24時間取引量",
            "t4": "24時間最高価格",
            "t5": "24時間最低価格",
            "t6": "マーケット",
            "t7": "取引ダイナミクス",
            "t8": "自分の注文",
            "t9": "市場価格",
            "t10": "検索",
            "t11": "通貨",
            "t12": "最新価格",
            "t13": "価格変動率",
            "t14": "データがありません",
            "t15": "注文を出す",
            "t16": "注文数量",
            "t17": "残高：",
            "t18": "注文単価",
            "t19": "予想利益",
            "t20": "手数料",
            "t21": "総支払額",
            "t22": "キャンセル",
            "t23": "確認",
            "t24": "購入を確認",
            "t25": "入金先アドレス",
            "t26": "購入数量",
            "t27": "単価",
            "t28": "手数料",
            "t29": "総支払額",
            "t30": "キャンセル",
            "t31": "確認",
            "t32": "読み込み中...",
            "t33": "ネットワーク異常",
            "t34": "注文数量と単価を入力してください",
            "t35": "トークン残高が不足しています",
            "t36": "パラメータ確認中...",
            "t37": "注文成功",
            "t38": "注文失敗",
            "t39": "注文失敗",
            "t40": "残高確認中...",
            "t41": "購入成功",
            "t42": "購入失敗",
            "t43": "購入失敗",
            "t44": "追加成功",
            "t45": "追加失敗",
            "t46": "トークン情報",
            "t47": "トークン名",
            "t48": "トークン契約",
            "t49": "発行量",
            "t50": "閉じる",
            "t51": "ウォレットに追加",
            "t52": "下に引いて更新...",
            "t53": "リリースで更新...",
            "t54": "読み込み中...",
            "t55": "すべて",
            "t56": "注：BoTeトークンの各転送で、総額の1％がブラックホールに送られ、破壊されます。実際に破壊される数量は契約での実際の破壊数に基づきます。"
        },
        "delegation": {
            "t1": "現在の委託",
            "t2": "過去の委託",
            "t3": "取引履歴",
            "t4": "アイコン",
            "t5": "板深度",
            "t6": "取引ダイナミクス",
            "t7": "アイコン",
            "t8": "購入",
            "t9": "販売",
            "t10": "価格",
            "t11": "購入数量",
            "t12": "(最低購入数量：10)",
            "t13": "手数料:",
            "t14": "総支払額",
            "t15": "残高：",
            "t16": "売却数量",
            "t17": "価格（K）",
            "t18": "取引量",
            "t19": "時間",
            "t20": "現在のトークンは取引に対応していません",
            "t21": "推奨",
            "t22": "お気に入り",
            "t23": "剩餘数量",
            "t24": "數量",
            "t25": 'もっと',
            "t26": "入力された数値は0.0001未満であってはなりません。再入力してください。",
            "t27": 'もっと見る',
            "t28": '滑点（{n}%）',
            "t29": "入力された数値は0.0001未満であってはなりません。再入力してください。",
            "t30": "入力された数値は0.0001未満であってはなりません。再入力してください。",
            "t31": '預估'
        },
        "market": {
            "t1": "価格優先",
            "t2": "数量優先",
            "t3": "番号",
            "t4": "単価",
            "t5": "購入",
            "t6": "データがありません"
        },
        "trade": {
            "t1": "取引",
            "t2": "総額",
            "t3": "番号",
            "t4": "時間",
            "t5": "取引Hash",
            "t6": "データがありません"
        },
        "order": {
            "t1": "現在の注文",
            "t2": "過去の注文",
            "t3": "購入注文",
            "t4": "番号",
            "t5": "注文時間",
            "t6": "取引時間",
            "t7": "取引Hash",
            "t8": "完了済み",
            "t9": "キャンセル済み",
            "t10": "注文を撤回",
            "t11": "データがありません",
            "t12": "注文Hash",
            "t13": "取引Hash",
            "t14": "キャンセルHash",
            "t15": "単価",
            "t16": "部分取引",
            "t17": "未完了",
            "t18": "状態",
            "t19": "売却",
            "t20": "購入",
            "t21": "未完了数量：",
            "t22": "委託を撤回",
            "t23": "キャンセル済み",
            "t24": "撤回成功",
            "t25": "撤回失敗",
            "t26": "未完了",
            "t27": "委託中",
            "t28": "売盘市场",
            "t29": "买盘市场"
        }
    }
};

const i18n = new VueI18n({
    locale: 'zh-hant', // 默认语言
    fallbackLocale: 'zh-hant', // 未找到翻译时的默认语言
    messages
});

export default i18n;
